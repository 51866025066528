// Generated by Framer (b2780b5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["v7bpFzdE5", "eZNyviMDu"];

const serializationHash = "framer-UzT3l"

const variantClassNames = {eZNyviMDu: "framer-v-ok3kwt", v7bpFzdE5: "framer-v-1g9fhrg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {close: "eZNyviMDu", open: "v7bpFzdE5"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "v7bpFzdE5"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "v7bpFzdE5", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1g9fhrg", className, classNames)} data-framer-name={"open"} layoutDependency={layoutDependency} layoutId={"v7bpFzdE5"} ref={ref ?? ref1} style={{rotate: 0, ...style}} variants={{eZNyviMDu: {rotate: -180}}} {...addPropertyOverrides({eZNyviMDu: {"data-framer-name": "close"}}, baseVariant, gestureVariant)}><SVG className={"framer-1fsx4dp"} data-framer-name={"Ic-chevron-16"} fill={"black"} intrinsicHeight={16} intrinsicWidth={16} layoutDependency={layoutDependency} layoutId={"qV36Sa93Y"} svg={"<svg width=\"16\" height=\"16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><g clip-path=\"url(#a)\"><path d=\"m13 6-5 5-5-5\" stroke=\"#848484\" stroke-width=\"1.5\" stroke-linecap=\"square\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UzT3l.framer-tovosc, .framer-UzT3l .framer-tovosc { display: block; }", ".framer-UzT3l.framer-1g9fhrg { height: 16px; overflow: hidden; position: relative; width: 16px; }", ".framer-UzT3l .framer-1fsx4dp { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 16px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"eZNyviMDu":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Frameri2k1kBdSn: React.ComponentType<Props> = withCSS(Component, css, "framer-UzT3l") as typeof Component;
export default Frameri2k1kBdSn;

Frameri2k1kBdSn.displayName = "ic-chevron-16";

Frameri2k1kBdSn.defaultProps = {height: 16, width: 16};

addPropertyControls(Frameri2k1kBdSn, {variant: {options: ["v7bpFzdE5", "eZNyviMDu"], optionTitles: ["open", "close"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Frameri2k1kBdSn, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})